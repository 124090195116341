header > h4, h2 {
  color: #fff;
}


.footer{
  background-color: #fafafa;
}

.h5-footer{
  font-size: 0.9rem
}

.coll > span, a, i{
  font-size: 0.8rem;
}

main{
  min-height: 80vh;
}

header{
  background-image: url('https://rhapsodybible.org/wp-content/uploads/2019/03/bg-header-rb.jpg');
  background-repeat: repeat no-repeat;
}

.button{
  margin: 5px 0px;
  width: stretch;
  background-color: #78c2ad !important;
  border-color: #78c2ad !important;
  color:#fff
}

.buttons{
  margin: 5px 0px;
  width: stretch;
  background-color: #035640 !important;
  border-color: #035640 !important;
  color:#fff
}

.buttons2{
  margin: 5px 0px;
  width: stretch;
  background-color: #0251C4 !important;
  border-color: #0251C4 !important;
  color:#fff
}

.buttons3{
  margin: 5px 0px;
  width: stretch;
  background-color: #5FC8AF !important;
  border-color: #5FC8AF  !important;
  color:#fff
}
.form-inline .form-control {
  display: inline-block;
  width: 22rem !important;
  vertical-align: middle;
}
.a{
  cursor: pointer;
}
